import { Box } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { RpmExportedFilters } from '../../../interfaces/billing';
import { BillingClaim } from '../../../interfaces/billing.rpm';
import { IClinic } from '../../../interfaces/clinic';
import { SelectOption } from '../../../interfaces/common';
import { PayerItem } from '../../../interfaces/eligibility';
import { BillingService } from '../../../services';
import { CommonService } from '../../../services/common';
import coreService from '../../../services/core';
import eligibilityService from '../../../services/eligibility';
import { RPMFilters } from '../common/RPMFilters';
import {
  FilterConfiguration,
  Filters,
  INITIAL_PAGINATION,
  LoadingStates,
  RPMCptCodes,
} from '../types/rpms.types';
import { RPMExportResultsTable } from './RPMExportResultsTable';

const filterConfigurations = [
  {
    key: 'clinic',
    label: 'Clinic',
    options: [],
    section: 'RPM Export Results',
    type: 'select',
  },
  {
    key: 'insuranceCompany',
    label: 'Insurance Company',
    options: [],
    section: 'RPM Export Results',
    type: 'autocomplete',
    fetchOptions: async (searchTerm: string) => {
      try {
        const response = await eligibilityService.getPayers({
          filter: searchTerm || null,
        });
        const options =
          response?.data?.map((payer: PayerItem) => ({
            label: payer.name,
            value: payer.id,
          })) || [];
        return options;
      } catch (error) {
        console.error('Error fetching insurance companies:', error);
        return [];
      }
    },
  },
  {
    key: 'dateOfService',
    label: 'Date Of Service',
    type: 'date',
  },
  {
    key: 'cptCodes',
    label: 'CPT Codes',
    options: Object.values(RPMCptCodes).map((value) => ({ value, label: value })),
    section: 'Patient Selection',
    type: 'select',
  },
  {
    key: 'memberSearch',
    label: 'Member Search',
    options: [],
    section: 'RPM Export Results',
    type: 'autocomplete',
    fetchOptions: async (searchTerm: string) => {
      try {
        if (!searchTerm) {
          return [];
        }

        const response = await coreService.fetchMembers({
          searchTerm,
        });
        const options =
          response?.data?.map((member) => ({
            label: member.fullName,
            value: member.uuid,
          })) || [];
        return options;
      } catch (error) {
        console.error('Error fetching members:', error);
        return [];
      }
    },
  },
] as FilterConfiguration<Filters>[];

const initialFilters: Filters = {
  clinic: [],
  insuranceCompany: [],
  dateOfService: [],
  cptCodes: [],
  memberSearch: [],
};

export const RPMExportResults: React.FC = () => {
  const [data, setData] = useState<BillingClaim[]>([]);
  const [pagination, setPagination] = useState(INITIAL_PAGINATION);
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [clinicOptions, setClinicOptions] = useState<SelectOption[]>([]);
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({
    isApplying: false,
    isClearing: false,
  });

  const buildExportParameters = (
    currentFilters: Filters,
    page: number,
    limit: number,
  ): RpmExportedFilters => ({
    clinicIds: currentFilters.clinic.map((id) => parseInt(id)),
    insuranceCompany: currentFilters.insuranceCompany,
    cptCodes: currentFilters.cptCodes,
    dateOfService: currentFilters.dateOfService?.join('') || '',
    memberIds: currentFilters.memberSearch,
    page,
    limit,
  });

  const fetchExportedClaims = useCallback(async (parameters: RpmExportedFilters) => {
    setLoadingStates((prev) => ({ ...prev, isApplying: true }));

    try {
      const response = await new BillingService().getExportedClaims(parameters);
      if (response) {
        setData(response.data);
        setPagination(response.pagination);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, isApplying: false }));
    }
  }, []);

  const fetchClinics = async () => {
    try {
      const clinics = await CommonService.getClinics();
      setClinicOptions(
        clinics.map((clinic: IClinic) => ({
          label: clinic.name,
          value: `${clinic.id}`,
        })),
      );
    } catch (error) {
      console.error('Error fetching clinics:', error);
    }
  };

  useEffect(() => {
    fetchClinics();
  }, []);

  useEffect(() => {
    const parameters = buildExportParameters(filters, pagination.currentPage, pagination.limit);
    fetchExportedClaims(parameters);
  }, [fetchExportedClaims, filters, pagination.currentPage, pagination.limit]);

  const handleApplyFilters = async (appliedFilters: Filters) => {
    setLoadingStates((prev) => ({ ...prev, isApplying: true }));
    setFilters(appliedFilters);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));

    const parameters = buildExportParameters(appliedFilters, 1, pagination.limit);
    await fetchExportedClaims(parameters);
  };

  const handleClearFilters = async () => {
    setLoadingStates((prev) => ({ ...prev, isClearing: true }));
    setFilters(initialFilters);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));

    const parameters = buildExportParameters(initialFilters, 1, pagination.limit);
    await fetchExportedClaims(parameters);

    setLoadingStates((prev) => ({ ...prev, isClearing: false }));
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, currentPage: newPage }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLimit = parseInt(event.target.value, 10);
    setPagination((prev) => ({
      ...prev,
      limit: newLimit,
      currentPage: 1,
    }));
  };

  const updatedFilterConfigurations = filterConfigurations.map((config) =>
    config.key === 'clinic' ? { ...config, options: clinicOptions } : config,
  );

  return (
    <Box>
      <RPMFilters<Filters>
        configurations={updatedFilterConfigurations}
        onApplyFilters={handleApplyFilters}
        onClearFilters={handleClearFilters}
        initialFilters={initialFilters}
        loadingStates={loadingStates}
      />

      <RPMExportResultsTable
        onRowsPerPageChange={handleChangeRowsPerPage}
        isLoading={loadingStates.isApplying}
        pagination={pagination}
        data={data}
        onPageChange={handlePageChange}
      />
    </Box>
  );
};
