import axios from 'axios';
import {
  AperoSyncClinicsResponse,
  BillingTransform,
  EHRClaimSync,
  EHRClaimSyncRequestPayload,
  EHRClaimSyncRequestResults,
  EHRSyncPayload,
  EhrClaimResponse,
  EhrClaimResyncPayload,
  EhrClaimsResponse,
  RpmCalculationFilters,
  RpmExportedFilters,
  RpmPatientSelectionFilters,
} from '../interfaces/billing';
import {
  BillingClaimsExportedResponse,
  BillingClaimsPreviewResponse,
  BillingNoteResponse,
  BillingPatientsResponse,
} from '../interfaces/billing.rpm';
import { AuthService } from './auth';

const ENARA_API_URL = process.env.REACT_APP_ENARA_BASE_URL;
const BILLING_API_URL = process.env.REACT_APP_PUBLIC_BILLING_API_BASE_URL;

export class BillingService {
  public async getTransformedFile(
    data: FormData,
    clinicId: number,
    isHL7: boolean,
  ): Promise<BillingTransform[] | null> {
    const authData = AuthService.getAuth();
    try {
      const response = await axios.post(`${ENARA_API_URL}/billing/billing/v1/transform`, data, {
        headers: {
          Authorization: `Bearer ${authData?.auth_token}`,
          'content-type': 'multipart/form-data',
          'Clinic-Id': `${clinicId}`,
          ...(isHL7 && { Hl7: `${isHL7}` }),
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  public async runEhrClaimSync(payload: EHRSyncPayload): Promise<AperoSyncClinicsResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.post<AperoSyncClinicsResponse>(
        `${ENARA_API_URL}/billing/billing/v1/ehr-claims-syncs`,
        payload,
        { headers: { Authorization: `Bearer ${authData?.auth_token}` } },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async reSyncEhrClaim(
    claimId: number,
    payload?: EhrClaimResyncPayload,
  ): Promise<EhrClaimResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.post<EhrClaimResponse>(
        `${ENARA_API_URL}/billing/billing/v1/ehr-claims/${claimId}/syncs`,
        payload,
        { headers: { Authorization: `Bearer ${authData?.auth_token}` } },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getClaimSyncRequests(
    payload: EHRClaimSyncRequestPayload,
  ): Promise<EHRClaimSyncRequestResults | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get(
        `${ENARA_API_URL}/billing/billing/v1/ehr-claim-sync-requests`,
        {
          headers: { Authorization: `Bearer ${authData?.auth_token}` },
          params: payload,
        },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getClaims(payload: EHRClaimSync): Promise<EhrClaimsResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get(`${ENARA_API_URL}/billing/billing/v1/ehr-claims-syncs`, {
        headers: { Authorization: `Bearer ${authData?.auth_token}` },
        params: payload,
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getClaim(id: number): Promise<EhrClaimResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get(`${ENARA_API_URL}/billing/billing/v1/ehr-claims/${id}`, {
        headers: { Authorization: `Bearer ${authData?.auth_token}` },
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getPatients({
    clinicIds = [],
    optOutStatus = [],
    icd10Codes = [],
    insuranceCompanyIds = [],
    cptCodes = [],
    rpmStatuses = [],
    memberIds = [],
    limit = 10,
    page = 1,
  }: RpmPatientSelectionFilters): Promise<BillingPatientsResponse | null> {
    const authData = AuthService.getAuth();

    try {
      // removing empty array and empty items.
      const parameters = this.prepareParameters({
        clinicIds,
        optOutStatus,
        icd10Codes,
        insuranceCompanyIds,
        cptCodes,
        rpmStatuses,
        memberUuids: memberIds,
        limit,
        page,
      });

      const response = await axios.get<BillingPatientsResponse>(`${BILLING_API_URL}/patients`, {
        headers: { Authorization: `Bearer ${authData?.auth_token}` },
        params: parameters,
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  // TODO: Update the return type of this method
  public async generateClaimsBulk(memberUuids: string[]) {
    const authData = AuthService.getAuth();

    try {
      const body = {
        claims: memberUuids.map((memberUuid) => ({ memberUuid })),
      };

      const response = await axios.post(`${BILLING_API_URL}/claims/bulk`, body, {
        headers: { Authorization: `Bearer ${authData?.auth_token}` },
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async generateClaimsBulkWithFilters(
    filters: Partial<{
      clinicIds: number[];
      icd10Codes: string[];
      insuranceCompanyIds: string[];
    }>,
    memberUuidsToExclude?: string[],
  ) {
    const authData = AuthService.getAuth();

    try {
      const body = {
        ...filters,
        ...(memberUuidsToExclude?.length && {
          exclude: memberUuidsToExclude.map((memberUuid) => ({ memberUuid })),
        }),
      };

      const response = await axios.post(`${BILLING_API_URL}/claims/bulk-with-filters`, body, {
        headers: { Authorization: `Bearer ${authData?.auth_token}` },
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async exportClaims(claimIds: number[]) {
    const authData = AuthService.getAuth();

    try {
      const body = {
        claims: claimIds.map((id) => ({ id })),
      };

      const response = await axios.post(`${BILLING_API_URL}/claims/export`, body, {
        headers: { Authorization: `Bearer ${authData?.auth_token}` },
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async exportClaimsWithFilters(
    filters: Partial<{
      clinicIds: number[];
      insuranceCompanyIds: string[];
      dateOfService: string;
      placeOfService: string[];
      cptCodes: string[];
      memberUuids: string[];
    }>,
    claimIdsToExclude?: number[],
  ) {
    const authData = AuthService.getAuth();

    try {
      const body = this.prepareParameters({
        ...filters,
        exclude: claimIdsToExclude,
      });

      const response = await axios.post(`${BILLING_API_URL}/claims/export-with-filters`, body, {
        headers: { Authorization: `Bearer ${authData?.auth_token}` },
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getClaimsCalculated(
    parameters: RpmCalculationFilters,
  ): Promise<BillingClaimsPreviewResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const finalParameters = this.prepareParameters(parameters);

      const response = await axios.get<BillingClaimsPreviewResponse>(
        `${BILLING_API_URL}/claims/preview`,
        {
          headers: { Authorization: `Bearer ${authData?.auth_token}` },
          params: finalParameters,
        },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getExportedClaims(filters: RpmExportedFilters) {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get<BillingClaimsExportedResponse>(
        `${BILLING_API_URL}/claims/exported`,
        {
          headers: { Authorization: `Bearer ${authData?.auth_token}` },
          params: filters,
        },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async discardClaims(claimIds: number[]) {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.patch(
        `${BILLING_API_URL}/claims/discard`,
        {
          claimsIds: claimIds,
        },
        {
          headers: { Authorization: `Bearer ${authData?.auth_token}` },
        },
      );

      if (response.status !== 200) {
        console.log(`can't discard claims`);
        throw new Error(`can't discard claims: ${claimIds} `);
      }

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private prepareParameters(input: Record<string, any>) {
    return Object.fromEntries(
      Object.entries(input).filter(([_, value]) => {
        if (Array.isArray(value)) {
          if (value.length === 0) {
            return false;
          }

          return value.every((item) => item !== undefined && item !== null && item !== '');
        }

        return value !== undefined;
      }),
    );
  }

  public async getRpmNote(claimId: number): Promise<BillingNoteResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get<BillingNoteResponse>(
        `${BILLING_API_URL}/claims/${claimId}/note`,
        {
          headers: { Authorization: `Bearer ${authData?.auth_token}` },
        },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
