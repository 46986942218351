import { formatDate } from '@fullcalendar/core';
import { Box, Chip, Link, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { BillingClaim, BillingPagination } from '../../../interfaces/billing.rpm';
import { getCapitalizedWord } from '../../../util/stringFormat';
import { CompactTable } from '../../common/CompactTable/CompactTable';
import { Column } from '../../common/CustomTable';
import { getStatusChipColor } from '../common/ChipColors';
import { CodeChipList } from '../common/CodeChipList';
import { RpmLoading } from '../common/RpmLoading';
import { RpmStatus } from '../types/rpms.types';

interface RPMExportResultsTableProps {
  data: BillingClaim[];
  pagination: BillingPagination;
  isLoading: boolean;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RPMExportResultsTable: React.FC<RPMExportResultsTableProps> = ({
  data,
  pagination,
  isLoading,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const columnGroups = useMemo(
    () => [
      {
        id: 'claims',
        title: 'Calculated claims for the period',
        startColumn: 4,
        colspan: 6,
      },
    ],
    [],
  );

  const columns: Column[] = useMemo(
    () => [
      {
        key: 'id',
        label: 'ID',
      },
      {
        key: 'name',
        label: 'Name',
      },
      {
        key: 'clinic',
        label: 'Clinic',
      },
      {
        key: 'insurance',
        label: 'Insurance',
      },
      {
        key: 'claimId',
        label: 'Claim ID',
      },
      {
        key: 'dos',
        label: 'Date of Service',
      },
      {
        key: 'pos',
        label: 'POS',
      },
      {
        key: 'cptCodes',
        label: 'CPT Codes',
        width: 150,
      },
      {
        key: 'note',
        label: 'Note',
      },
      {
        key: 'amount',
        label: 'Amount',
        align: 'right',
      },
      {
        key: 'status',
        label: 'Status',
      },
      {
        key: 'issues',
        label: 'Issues',
      },
    ],
    [],
  );

  const tableRows = useMemo(
    () =>
      data.map((row) => ({
        id: row.id,
        name: [row.billingMember.firstName, row.billingMember.lastName].join(' '),
        clinic: row.billingMember.clinicId, // TODO: Should be the name
        insurance: row.billingMember.insuranceCompany,
        claimId: row.id ? ( // TODO: Should be invoice id
          <Link href={`/claims/${row.id}`} target='_blank'>
            {row.id}
          </Link>
        ) : (
          <Typography color='text.secondary'>-</Typography>
        ),
        dos: formatDate(row.dateOfService),
        pos: '-', // TODO: Should be POS
        cptCodes: <CodeChipList codes={row.cptCodes.map((code) => code.cptCode)} />,
        note: row.id ? ( // TODO: Should be the URL
          <Link href={`/notes/${row.id}`} target='_blank'>
            View Note
          </Link>
        ) : (
          <Typography color='text.secondary'>-</Typography>
        ),
        amount: '-',
        status: (
          <Chip
            label={getCapitalizedWord(
              row.status === RpmStatus.EXPORTED ? RpmStatus.VALID : RpmStatus.INVALID,
            )}
            sx={getStatusChipColor(
              row.status === RpmStatus.EXPORTED ? RpmStatus.VALID : RpmStatus.INVALID,
            )}
            size='small'
          />
        ),
        issues:
          row.errors && row.errors.length > 0 ? (
            <CodeChipList codes={row.errors} />
          ) : (
            <Typography color='text.secondary'>-</Typography>
          ),
      })),
    [data],
  );

  return (
    <Box position='relative'>
      {isLoading && <RpmLoading />}
      <CompactTable
        columns={columns}
        rows={tableRows}
        defaultText='No export results found'
        columnGroups={columnGroups}
        pagination={{
          rowsPerPageOptions: [10, 20, 30, 50],
          rowPerPage: pagination.limit,
          page: pagination.currentPage,
          totalCount: pagination.totalCount,
          onPageChange,
          onRowsPerPageChange,
        }}
      />
    </Box>
  );
};
