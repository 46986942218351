import { Box, Checkbox, Chip, Link, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BillingClaimPreview, BillingPagination } from '../../../interfaces/billing.rpm';
import { StyledTheme } from '../../../styles/styleTheme';
import { getCapitalizedWord } from '../../../util/stringFormat';
import { CompactTable } from '../../common/CompactTable/CompactTable';
import { Column } from '../../common/CustomTable';
import { getStatusChipColor } from '../common/ChipColors';
import { CodeChipList } from '../common/CodeChipList';
import { RpmLoading } from '../common/RpmLoading';
import RPMNotePreviewModal from '../note';
import { RpmStatus } from '../types/rpms.types';

interface RPMCalculationTableProps {
  onRowSelectionChange: (selectedRows: number[]) => void;
  selectedRows: number[];
  onRowExclusionChange: (excludedRows: { id: number; index: number }[]) => void;
  excludedRows: { id: number; index: number }[];
  isLoading?: boolean;
  data: BillingClaimPreview[];
  pagination: BillingPagination;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
  onAllRecordsSelected: (allRecordsSelected: boolean) => void;
  allRecordsSelected: boolean;
}

export const RPMCalculationTable: React.FC<RPMCalculationTableProps> = ({
  isLoading = false,
  onRowSelectionChange,
  selectedRows,
  onRowExclusionChange,
  excludedRows,
  data,
  pagination,
  onPageChange,
  onRowsPerPageChange,
  onAllRecordsSelected,
  allRecordsSelected,
}) => {
  // States
  const [selectedClaimId, setSelectedClaimId] = useState<number | null>(null);
  const [isRpmNotePreviewModalOpen, setIsRpmNotePreviewModalOpen] = useState(false);

  // Row Selection Handlers
  const handleRowSelection = useCallback(
    (id: number) => {
      const claim = data.find((c) => c.id === id);

      if (!claim?.id) {
        return;
      }

      const updatedSelectedRows = selectedRows.includes(id)
        ? selectedRows.filter((rowId) => rowId !== id)
        : [...selectedRows, id];

      onRowSelectionChange(updatedSelectedRows);

      if (allRecordsSelected) {
        const updatedExcludedRows = selectedRows.includes(id)
          ? [...excludedRows, { id: claim.id, index: id }]
          : excludedRows.filter(({ index }) => index !== id);

        onRowExclusionChange(updatedExcludedRows);
      }
    },
    [
      data,
      onRowSelectionChange,
      selectedRows,
      allRecordsSelected,
      excludedRows,
      onRowExclusionChange,
    ],
  );

  const handleSelectAll = useCallback(() => {
    if (!!selectedRows.length) {
      onRowSelectionChange([]);
    } else {
      onAllRecordsSelected(true);
    }
  }, [onAllRecordsSelected, selectedRows, onRowSelectionChange]);

  const handleDeselectAll = useCallback(() => {
    onAllRecordsSelected(false);
    onRowExclusionChange([]);
  }, [onAllRecordsSelected, onRowExclusionChange]);

  // Pagination Handlers
  const handlePageChange = useCallback(
    (newPage: number) => {
      onPageChange(newPage);
    },
    [onPageChange],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      onRowsPerPageChange(newRowsPerPage);
    },
    [onRowsPerPageChange],
  );

  // Modal Handlers
  const handleOpenRpmNotePreviewModal = useCallback((claimId: number | null) => {
    setSelectedClaimId(claimId);
    setIsRpmNotePreviewModalOpen(true);
  }, []);

  const handleCloseRpmNotePreviewModal = useCallback(() => {
    setSelectedClaimId(null);
    setIsRpmNotePreviewModalOpen(false);
  }, []);

  const columnGroups = useMemo(
    () => [
      {
        id: 'claims',
        title: 'Calculated claims for the period',
        startColumn: 4,
        colspan: 5,
      },
    ],
    [],
  );

  // Columns Configuration
  const columns: Column[] = useMemo(() => {
    const validClaims = data.filter((row) => row.errors.length === 0);
    const validClaimsCount = validClaims.length;
    const validClaimIds = validClaims.map((claim) => claim.id);
    const selectedValidClaimsInPage = selectedRows.filter((id) => validClaimIds.includes(id));

    const someValidClaimsSelected =
      selectedValidClaimsInPage.length > 0 && selectedValidClaimsInPage.length < validClaimsCount;

    return [
      {
        key: 'id',
        label: 'ID',
      },
      {
        key: 'name',
        label: 'Name',
      },
      {
        key: 'clinic',
        label: 'Clinic',
      },
      {
        key: 'insurance',
        label: 'Insurance',
      },
      {
        key: 'select',
        label: (
          <Tooltip title='Select/deselect all valid patients across pages' placement='right'>
            <span>
              <Checkbox
                size='small'
                style={{ color: StyledTheme.tealDark }}
                indeterminate={someValidClaimsSelected}
                checked={allRecordsSelected}
                onChange={(event) =>
                  event.target.checked ? handleSelectAll() : handleDeselectAll()
                }
                aria-label='Select Valid Rows'
              />
            </span>
          </Tooltip>
        ),
        align: 'center',
      },
      {
        key: 'dos',
        label: 'DOS',
      },
      {
        key: 'pos',
        label: 'POS',
      },
      {
        key: 'cptCodes',
        label: 'CPT Codes',
      },
      {
        key: 'note',
        label: 'Note',
      },
      {
        key: 'status',
        label: 'Status',
      },
      {
        key: 'issues',
        label: 'Issues',
      },
    ];
  }, [data, handleDeselectAll, handleSelectAll, selectedRows, allRecordsSelected]);

  const tableRows = useMemo(
    () =>
      data.map((row) => ({
        id: (
          <Link href='#' target='_blank' rel='noopener noreferrer'>
            {row.id}
          </Link>
        ),
        name: row.memberName,
        clinic: row.clinicName,
        insurance: row.billingMember?.insuranceCompany ?? '',
        select: (
          <Checkbox
            style={{ color: StyledTheme.tealDark }}
            size='small'
            checked={selectedRows.includes(row.id)}
            onChange={() => handleRowSelection(row.id)}
          />
        ),
        dos: row.dateOfService,
        pos: row.placeOfService ?? '',
        status: (
          <Chip
            label={getCapitalizedWord(
              row.status === RpmStatus.CREATED ? RpmStatus.VALID : RpmStatus.INVALID,
            )}
            sx={getStatusChipColor(
              row.status === RpmStatus.CREATED ? RpmStatus.VALID : RpmStatus.INVALID,
            )}
            size='small'
          />
        ),
        cptCodes: <CodeChipList codes={row.cptCodes.map((code) => code.cptCode)} />,
        note: (
          <Link
            component='button'
            variant='body2'
            onClick={() => handleOpenRpmNotePreviewModal(row.id)}>
            View Note
          </Link>
        ),
        issues: row.errors,
      })),
    [data, handleOpenRpmNotePreviewModal, handleRowSelection, selectedRows],
  );

  useEffect(() => {
    if (allRecordsSelected) {
      const allValidIds = data.filter((claim) => !claim.errors?.length).map(({ id }) => id);
      onRowSelectionChange(allValidIds);
    } else {
      onRowSelectionChange([]);
    }
  }, [allRecordsSelected, data, onRowSelectionChange]);

  return (
    <>
      <Box position='relative'>
        {isLoading && <RpmLoading />}
        <CompactTable
          columns={columns}
          rows={tableRows}
          defaultText='No claims calculations found'
          columnGroups={columnGroups}
          pagination={{
            rowsPerPageOptions: [10, 20, 30, 50],
            rowPerPage: pagination.limit,
            page: pagination.currentPage,
            totalCount: pagination.totalCount,
            onPageChange: handlePageChange,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
        />
      </Box>

      <RPMNotePreviewModal
        open={isRpmNotePreviewModalOpen}
        onClose={handleCloseRpmNotePreviewModal}
        claimId={selectedClaimId!}
      />
    </>
  );
};
