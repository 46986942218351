export const StyledTheme = {
  black: '#000000',
  grayLight: '#C4C4C4',
  grayDark: '#70767c',
  greenLight: '#D3FFCC',
  greenLightMui: '#81c784',
  greenMint: '#1eaf84',
  aquamarine: '#20D5A8',
  yellowLight: '#FFFEB3',
  yellowLightMui: '#ffb74d',
  redLight: '#FCE4EC',
  redLightMui: '#e74c3c',
  white: '#FFFFFF',
  tealDark: '#1A667A',
  blueMunsell: '#248CA8',
  grayDisabled: 'E0E0E0',
  turquoise: '#ABD9D6',
  salmon: '#F37962',
  masala: '#4B4846',
  orangeLight: '#FFD261',
  redDark: '#D32F2F',
  fontProximaNova:
    "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Proxima Nova Rg', 'Source Serif Pro'",
};
