import { Box } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RpmPatientSelectionFilters } from '../../../interfaces/billing';
import { BillingPagination, BillingPatient } from '../../../interfaces/billing.rpm';
import { IClinic } from '../../../interfaces/clinic';
import { SelectOption } from '../../../interfaces/common';
import { PayerItem } from '../../../interfaces/eligibility';
import { ConditionStatus } from '../../../interfaces/health-data';
import { BillingService } from '../../../services';
import { CommonService } from '../../../services/common';
import coreService from '../../../services/core';
import eligibilityService from '../../../services/eligibility';
import healthDataService from '../../../services/health-data';
import { RPMFilters } from '../common/RPMFilters';
import { FilterConfiguration, Filters, LoadingStates, RPMCptCodes } from '../types/rpms.types';
import { PatientSelectionTable } from './PatientSelectionTable';

interface PatientSelectionProps {
  onAdvance: () => void;
}

const filterConfigurations = [
  {
    key: 'clinic',
    label: 'Clinic',
    options: [],
    section: 'Patient Selection',
    type: 'select',
  },
  {
    key: 'optOutStatus',
    label: 'Opt Out Status',
    options: [
      { value: 'disabled', label: 'Disabled' },
      { value: 'enabled', label: 'Enabled' },
    ],
    section: 'Patient Selection',
    type: 'select',
  },
  {
    key: 'icd10Codes',
    label: 'ICD10 Codes',
    options: [],
    section: 'Patient Selection',
    type: 'select',
  },
  {
    key: 'insuranceCompany',
    label: 'Insurance Company',
    options: [],
    section: 'Patient Selection',
    type: 'autocomplete',
    fetchOptions: async (searchTerm: string) => {
      try {
        const response = await eligibilityService.getPayers({
          filter: searchTerm || null,
        });
        const options =
          response?.data?.map((payer: PayerItem) => ({
            label: payer.name,
            value: payer.id,
          })) || [];
        return options;
      } catch (error) {
        console.error('Error fetching insurance companies:', error);
        return [];
      }
    },
  },
  {
    key: 'cptCodes',
    label: 'CPT Codes',
    options: Object.values(RPMCptCodes).map((value) => ({ value, label: value })),
    section: 'Patient Selection',
    type: 'select',
  },
  {
    key: 'rpmStatus',
    label: 'RPM Status',
    options: [
      { value: 'valid', label: 'Valid' },
      { value: 'invalid', label: 'Invalid' },
    ],
    section: 'Patient Selection',
    type: 'select',
  },
  {
    key: 'memberSearch',
    label: 'Member Search',
    options: [],
    section: 'Patient Selection',
    type: 'autocomplete',
    fetchOptions: async (searchTerm: string) => {
      try {
        if (!searchTerm) {
          return [];
        }

        const response = await coreService.fetchMembers({
          searchTerm,
        });
        const options =
          response?.data?.map((member) => ({
            label: member.fullName,
            value: member.uuid,
          })) || [];
        return options;
      } catch (error) {
        console.error('Error fetching members:', error);
        return [];
      }
    },
  },
] as FilterConfiguration<Filters>[];

const initialFilters: Filters = {
  clinic: [],
  optOutStatus: [],
  icd10Codes: [],
  insuranceCompany: [],
  cptCodes: [],
  rpmStatus: [],
  memberSearch: [],
};

export const PatientSelection: React.FC<PatientSelectionProps> = ({ onAdvance }) => {
  const [patientsData, setPatientsData] = useState<BillingPatient[]>([]);
  const [pagination, setPagination] = useState<BillingPagination>({
    totalCount: 0,
    totalPages: 0,
    currentPage: 1,
    limit: 30,
  });
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [clinicOptions, setClinicOptions] = useState<SelectOption[]>([]);
  const [icd10CodeOptions, setIcd10CodeOptions] = useState<SelectOption[]>([]);
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({
    isApplying: false,
    isClearing: false,
    isProcessing: false,
  });
  const [skipEffect, setSkipEffect] = useState(false);
  const [allRecordsSelected, setAllRecordsSelected] = useState(false);
  const [excludedRows, setExcludedRows] = useState<{ uuid: string; index: number }[]>([]);

  const fetchClinics = async () => {
    try {
      const clinics = await CommonService.getClinics();
      setClinicOptions(
        clinics.map((clinic: IClinic) => ({ label: clinic.name, value: `${clinic.id}` })),
      );
    } catch (error) {
      console.error('Error fetching clinics:', error);
    }
  };

  const fetchIcd10CodeOptions = async () => {
    try {
      const icd10Codes = await healthDataService.findAllConditions({
        status: ConditionStatus.ACTIVE,
        pageSize: 100, // TODO: for now pagination is not needed, but we need to handle multiple pages soon
      });
      if (icd10Codes?.data) {
        setIcd10CodeOptions(
          icd10Codes.data.conditions.map(({ condition }) => ({
            value: condition.icd10Code,
            label: condition.icd10Code,
          })),
        );
      }
    } catch (error) {
      console.error('Error fetching available icd10 codes:', error);
    }
  };

  const fetchPatients = async (parameters: RpmPatientSelectionFilters) => {
    setLoadingStates((prev) => ({ ...prev, isApplying: true }));

    try {
      const response = await new BillingService().getPatients(parameters);
      if (response) {
        setPatientsData(response.data);
        setPagination(response.pagination);
      }
    } catch (error) {
      console.error('Error fetching patients:', error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, isApplying: false }));
    }
  };

  const handlePageChange = (newPage: number) => {
    const parameters: RpmPatientSelectionFilters = {
      clinicIds: filters.clinic.map((id) => parseInt(id)) || [],
      insuranceCompanyIds: filters.insuranceCompany || [],
      cptCodes: filters.cptCodes || [],
      icd10Codes: filters.icd10Codes ? filters.icd10Codes.map((code) => code.toUpperCase()) : [],
      rpmStatuses: filters.rpmStatus || [],
      optOutStatus: filters.optOutStatus || [],
      memberIds: filters.memberSearch || [],
      page: newPage,
      limit: pagination.limit,
    };
    fetchPatients(parameters);
  };

  const handleRowsPerPageChange = (newLimit: number) => {
    const parameters: RpmPatientSelectionFilters = {
      clinicIds: filters.clinic.map((id) => parseInt(id)) || [],
      insuranceCompanyIds: filters.insuranceCompany || [],
      cptCodes: filters.cptCodes || [],
      icd10Codes: filters.icd10Codes ? filters.icd10Codes.map((code) => code.toUpperCase()) : [],
      rpmStatuses: filters.rpmStatus || [],
      optOutStatus: filters.optOutStatus || [],
      memberIds: filters.memberSearch || [],
      page: 1,
      limit: newLimit,
    };
    fetchPatients(parameters);
  };

  const updatedFilterConfigurations = useMemo(
    () =>
      filterConfigurations.map((config) => {
        switch (config.key) {
          case 'clinic':
            return { ...config, options: clinicOptions };
          case 'icd10Codes':
            return { ...config, options: icd10CodeOptions };
          default:
            return config;
        }
      }),
    [clinicOptions, icd10CodeOptions],
  );

  useEffect(() => {
    fetchClinics();
    fetchIcd10CodeOptions();
  }, []);

  useEffect(() => {
    if (!skipEffect) {
      const parameters: RpmPatientSelectionFilters = {
        clinicIds: filters.clinic.map((id) => parseInt(id)) || [],
        insuranceCompanyIds: filters.insuranceCompany || [],
        cptCodes: filters.cptCodes || [],
        icd10Codes: filters.icd10Codes ? filters.icd10Codes.map((code) => code.toUpperCase()) : [],
        rpmStatuses: filters.rpmStatus || [],
        optOutStatus: filters.optOutStatus || [],
        memberIds: filters.memberSearch || [],
        page: pagination.currentPage,
        limit: pagination.limit,
      };

      fetchPatients(parameters);
    }
  }, [filters, pagination.currentPage, pagination.limit, skipEffect]);

  const handleApplyFilters = async (newFilters: Filters) => {
    setLoadingStates((prev) => ({ ...prev, isApplying: true }));

    try {
      const parameters: RpmPatientSelectionFilters = {
        clinicIds: newFilters.clinic.map((id) => parseInt(id)) || [],
        insuranceCompanyIds: newFilters.insuranceCompany || [],
        cptCodes: newFilters.cptCodes || [],
        icd10Codes: newFilters.icd10Codes
          ? filters.icd10Codes.map((code) => code.toUpperCase())
          : [],
        rpmStatuses: newFilters.rpmStatus || [],
        optOutStatus: newFilters.optOutStatus || [],
        memberIds: newFilters.memberSearch || [],
        page: 1,
        limit: pagination.limit,
      };

      await fetchPatients(parameters);
      setFilters(newFilters);
      setSelectedRows([]);
    } catch (error) {
      console.error('Error applying filters:', error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, isApplying: false }));
    }
  };

  const handleClearFilters = async () => {
    setLoadingStates((prev) => ({ ...prev, isClearing: true }));
    setSkipEffect(true);

    try {
      const parameters = {
        clinicIds: [],
        insuranceCompaniesIds: [],
        cptCodes: [],
        icd10Codes: [],
        rpmStatuses: [],
        optOutStatus: [],
        memberIds: [],
        page: 1,
        limit: pagination.limit,
      };
      await fetchPatients(parameters);
      setFilters(initialFilters);
      setSelectedRows([]);
      setPagination((prev) => ({ ...prev, currentPage: 1 }));
    } finally {
      setLoadingStates((prev) => ({ ...prev, isClearing: false }));
      setSkipEffect(false);
    }
  };

  const handleProcessFilters = useCallback(async () => {
    if (selectedRows.length === 0) {
      alert('Please select at least one row before proceeding.');
      return;
    }

    setLoadingStates((prev) => ({ ...prev, isProcessing: true }));

    try {
      let response;
      const billingService = new BillingService();
      if (allRecordsSelected) {
        response = await billingService.generateClaimsBulkWithFilters(
          {
            ...(filters.clinic?.length && {
              clinicIds: filters.clinic.map((clinicId) => Number.parseInt(clinicId)),
            }),
            ...(filters.icd10Codes?.length && { icd10Codes: filters.icd10Codes }),
            ...(filters.insuranceCompany?.length && {
              insuranceCompanyIds: filters.insuranceCompany,
            }),
          },
          excludedRows.length ? excludedRows.map(({ uuid }) => uuid) : undefined,
        );
      } else {
        const patientsMap = new Map(patientsData.map((patient) => [patient.id, patient]));
        const memberUuids = selectedRows
          .map((id) => patientsMap.get(id)?.memberUuid)
          .filter((uuid): uuid is string => uuid !== undefined);

        response = await billingService.generateClaimsBulk(memberUuids);
      }

      if (response) {
        onAdvance();
      }
    } catch (error) {
      console.error('Error processing filters:', error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, isProcessing: false }));
    }
  }, [selectedRows, patientsData, onAdvance, allRecordsSelected, filters, excludedRows]);

  return (
    <Box>
      <RPMFilters<Filters>
        configurations={updatedFilterConfigurations}
        onApplyFilters={handleApplyFilters}
        onProcessFilters={handleProcessFilters}
        onClearFilters={handleClearFilters}
        onProcessLabel={
          allRecordsSelected
            ? `Calculate all selected patients`
            : `Calculate ${selectedRows.length} patients`
        }
        initialFilters={initialFilters}
        selectedCount={selectedRows.length}
        loadingStates={loadingStates}
        filters={filters}
        allRecordsSelection={allRecordsSelected}
      />

      <PatientSelectionTable
        allRecordsSelected={allRecordsSelected}
        data={patientsData}
        excludedRows={excludedRows}
        isLoading={loadingStates.isApplying}
        onAllRecordsSelected={setAllRecordsSelected}
        onPageChange={handlePageChange}
        onRowExclusionChange={setExcludedRows}
        onRowSelectionChange={setSelectedRows}
        onRowsPerPageChange={handleRowsPerPageChange}
        pagination={pagination}
        selectedRows={selectedRows}
      />
    </Box>
  );
};
