export type FilterValue = string[] | string | number;

export type RPMSelectionMode = 'page' | 'visible' | 'all';

export interface FilterConfiguration<T> {
  key: keyof T;
  label: string;
  section: string;
  multiple?: boolean;
  includeSelectAll?: boolean;
  options: { value: string; label: string }[];
  type: 'select' | 'autocomplete' | 'date';
  fetchOptions?: (searchTerm: string) => Promise<{ value: string; label: string }[]>;
}

// Patient Selection Filters
export interface Filters {
  [key: string]: string[];
}

export interface LoadingStates {
  isApplying: boolean;
  isClearing: boolean;
  isProcessing?: boolean;
  isDiscarding?: boolean;
}

// Clinic configuration details
export interface ClinicSpecificConfig {
  clinicName: string;
  procedures: {
    code: string;
    description: string;
  }[];
  disableRPM?: boolean;
}

// Insurance Configuration
export interface InsuranceConfiguration {
  disableRPM?: boolean;
  details: {
    code: string;
    disabled: string;
    maxClaimsPerCycle: number;
    pos: number;
  }[];
}

// RPM Configuration
export interface RpmConfiguration {
  optOutDate: string;
  disableRPM: string;
  insuranceCompany: string;
  insuranceConfig: InsuranceConfiguration;
  clinicConfig: ClinicSpecificConfig[];
}

export enum RpmStatus {
  VALID = 'valid',
  INVALID = 'invalid',
  EXPORTED = 'EXPORTED',
  CREATED = 'CREATED',
}

// Patient
export interface Patient {
  id: number;
  name: string;
  clinic: string;
  insurance: string;
  cptCodes: string[];
  icd10Codes: string[];
  membershipId: string;
  membershipStart: string;
  membershipStatus: string;
  rpmConfiguration: RpmConfiguration;
  rpmStatus: RpmStatus;
  rpmStatusDetails: string[];
  cycleStartDate?: string;
}

export interface Claim {
  id: number;
  name: string;
  clinic: string;
  insurance: string;
  dos: string;
  pos: number;
  cptCodes: string[];
  issues: string[];
  userId?: number;
  noteId?: number;
  amount?: number;
  status?: string;
}

export enum RPMCptCodes {
  CPT_99454 = '99454',
  CPT_99457 = '99457',
  CPT_99458 = '99458',
  CPT_99091 = '99091',
}

export enum RpmExclusionCriteria {
  ClinicDisabled = 'clinic-disabled',
  Icd10InsufficientCodes = 'icd10-insufficient-codes',
  Icd10NoECode = 'icd10-no-e-code',
  Icd10NonBillableCodesIncluded = 'icd10-non-billable-codes-included',
  InvalidStatus = 'invalid-status',
  NoInsurance = 'no-insurance',
  NotActive = 'not-active',
  PayerDisabled = 'payer-disabled',
  RpmDisabled = 'rpm-disabled',
  InsufficientHealthData = 'insufficient-health-data',
  InvalidBillingCycleDates = 'invalid-billing-cycle-dates',
  NoCptCodes = 'no-cpt-codes',
  NoCoverageType = 'no-coverage-type',
  NoProfileStatus = 'no-profile-status',
}

export const INITIAL_PAGINATION = {
  totalCount: 0,
  totalPages: 0,
  currentPage: 1,
  limit: 30,
};
